<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
.vs-dialog {
  max-width: 800px !important;
}
</style>
<template>
  <div class="vx-row mb-12">
    <div
      v-bind:class="[
        detail ? detailShow + ' vertical-divider' : '',
        detailHide,
      ]"
    >
      <data-table
        :responseData="responseData"
        :propsParams="params"
        :header="header"
        @reloadDataFromChild="reloadData"
      >
        <template slot="thead">
          <th width="7%">Action</th>
        </template>
        <template slot="tbody">
          <vs-tr
            :key="indextr"
            v-for="(tr, indextr) in responseData.purchasePaymentRequest"
            :class="tr.class"
          >
            <vs-td class="whitespace-no-wrap">
              <template>
                <div class="mt-4 flex justify-between">
                  <vx-tooltip text="Show Invoice" class="mr-4">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-eye"
                      v-on:click.stop="handleShow(tr)"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Print" class="mr-4">
                    <vs-button
                      v-on:click="printPDF(false, tr)"
                      type="line"
                      icon-pack="feather"
                      icon="icon-save"
                    />
                  </vx-tooltip>

                  <!-- <vx-tooltip text="Send to Approval" class="mr-4">
                    <vs-button
                      type="line"
                      color="green"
                      icon-pack="feather"
                      icon="icon-send"
                      v-on:click.stop="handleApprove(tr)"
                    />
                  </vx-tooltip> -->
                </div>
              </template>
            </vs-td>
            <vs-td>
              {{ tr.Code }}
            </vs-td>
            <vs-td> ({{ tr.SupplierCode }}) {{ tr.SupplierName }} </vs-td>
            <vs-td>
              {{
                tr.BankName +
                " - " +
                tr.AccountBranch +
                " (" +
                tr.AccountNumber +
                " " +
                tr.AccountName +
                ") "
              }}
            </vs-td>
            <vs-td>
              {{
                tr.ToBankName +
                " - " +
                tr.ToAccountBranch +
                " (" +
                tr.ToAccountNumber +
                " " +
                tr.ToAccountName +
                ") "
              }}
            </vs-td>
            <vs-td>
              {{ priceFormat(tr.Total) }} ({{ tr.CurrencyExchangeCode }})<br />
            </vs-td>
          </vs-tr>
        </template>
      </data-table>
      <!-- Approve Prompt -->
      <vs-prompt
        title="Information"
        color="primary"
        @close="closeDetail"
        :buttons-hidden="true"
        :active.sync="detailPrompt"
      >
        <div class="con-exemple-prompt prompt-md">
          <div class="vx-row mb-2">
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full input-readonly"
                label="Code Internal"
                name="Code Internal"
                v-model="purchaseInvoice.CodeInternal"
                readonly
              />
            </div>
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full input-readonly"
                label="Code External"
                name="Code External"
                v-model="purchaseInvoice.Code"
                readonly
              />
            </div>
          </div>
          <div class="vx-row mb-2">
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full input-readonly"
                label="Item Value"
                name="Item Value"
                v-model="itemValue"
                readonly
              />
            </div>
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full input-readonly"
                label="Tax Value"
                name="Tax Value"
                v-model="taxValue"
                readonly
              />
            </div>
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full input-readonly"
                label="Total"
                name="Total"
                v-model="total"
                readonly
              />
            </div>
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full input-readonly"
                label="Total Paid"
                name="Total Paid"
                v-model="totalPaid"
                readonly
              />
            </div>
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full input-readonly"
                label="Total Unpaid"
                name="Total Unpaid"
                v-model="totalUnpaid"
                readonly
              />
            </div>
            <div class="vx-col w-1/2">
              <vs-input
                class="w-full input-readonly"
                label="No Shipment"
                name="No Shipment"
                v-model="purchaseInvoice.DeliveryOrderNumber"
                readonly
              />
            </div>
          </div>
          <!-- <vs-divider style="width: 100%;"><b>Charge</b></vs-divider>
        <div class="vx-row ">
          <vs-table class="w-full" stripe border description :sst="true" :data="dataCharge">
            <template slot="thead">
              <vs-th>Charge Name</vs-th>
              <vs-th>Value</vs-th>
              <vs-th>Tax</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <div class="vx-row ml-0">
                    <span class="w-5/5">{{
                      data[indextr].charge_name.replace(/-/g, " ")
                    }}</span>
                  </div>
                </vs-td>
                <vs-td>
                  <div class="vx-row ml-0">
                    <span class="w-5/5">{{ data[indextr].value }}</span>
                  </div>
                </vs-td>
                <vs-td>
                  <div class="vx-row ml-0">
                    <span class="w-5/5">{{ data[indextr].tax }}</span>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div> -->
          <vs-divider style="width: 100%"><b>Payment</b></vs-divider>
          <vs-table
            stripe
            border
            description
            class="w-full"
            :sst="true"
            :data="dataPayment"
            max-height="400px"
          >
            <template slot="thead">
              <vs-td style="text-align: center"><b>Payment Account</b></vs-td>
              <vs-td style="text-align: center"><b>Payment Voucher</b></vs-td>
              <vs-td style="text-align: right"><b>Paid</b></vs-td>
            </template>

            <template slot-scope="{ data }">
              <vs-tr
                :data="tr"
                :key="indextr"
                v-for="(tr, indextr) in data"
                :class="data[indextr].class"
              >
                <vs-td
                  style="text-align: center"
                  :data="data[indextr].BankName"
                >
                  {{
                    data[indextr].BankID == 0
                      ? data[indextr].Type == 2
                        ? "DN"
                        : "-"
                      : data[indextr].BankName +
                        " " +
                        data[indextr].AccountNumber
                  }}
                </vs-td>
                <vs-td style="text-align: center">
                  {{ data[indextr].ReferenceCode }}
                </vs-td>
                <vs-td style="text-align: right">
                  {{
                    data[indextr].PaidInvoiceValue.toString().replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    )
                  }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </vs-prompt>

      <vs-prompt
        title="Confirmation"
        color="primary"
        @accept="approve"
        @cancel="closeDetail"
        @close="closeDetail"
        :buttons-hidden="false"
        :active.sync="approvalPrompt"
      >
        <div class="con-exemple-prompt">
          Payment
          <br />
          Are you sure to request approve
          <b>{{ this.selectedData.Code }}</b> voucher payment ?
          <br />
          <br />
          <vs-textarea v-model="notes" />
        </div>
      </vs-prompt>
    </div>
    <!-- form -->
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[
          detail ? detailShow + 'md:w-2/3 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>
          <vs-col
            vs-offset="0"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="1"
          >
            <vs-button
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
            ></vs-button>
          </vs-col>
          <detail-div
            @detailPurchaseInvoice="handleDetailPurchaseInvoice"
            @close="handleClose"
            :selected="selectedData"
          />
        </vs-row>
      </div>
    </transition>
    <vs-popup
      fullscreen
      title="fullscreen"
      :active.sync="showPdf"
      :button-close-hidden="false"
    >
      <div class="flex flex-col gap-6 h-full">
        <iframe :src="pdfUrlPopup" class="w-full" style="min-height: 80vh" />
        <div class="flex gap-3 w-full justify-end">
          <vs-button color="danger" @click="handleClosePopUp">Cancel</vs-button>
          <vs-Button @click="handlePrintPDFPreview">Print</vs-Button>
        </div>
      </div>
    </vs-popup>
    <vs-prompt
      title="Print Confirmation"
      color="primary"
      @accept="printPDF(true, selectedPaymentRequest)"
      @cancel="closeReprintPrompt"
      @close="closeReprintPrompt"
      :accept-text="'Yes'"
      :cancel-text="'No'"
      :buttons-hidden="false"
      :active.sync="reprintPrompt"
    >
      <div class="font-bold" style="width: fit-content">
        <span>You are going to reprint this proposal.</span>
        <br />
        <span>Are you sure ?</span>
      </div>
    </vs-prompt>
  </div>
</template>
<script>
import moment from "moment";
import detail from "./detail.vue";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import angkaTerbilang from "@develoka/angka-terbilang-js";
// import { dataPaymentCollection } from "../../../../../services/api/invoice";
export default {
  components: {
    "detail-div": detail,
  },
  props: {
    selected: Object,
    option: Object,
    paymentDate: {
      type: Object,
      default: () => ({}),
    },
    bankFrom: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      header: [
        {
          text: "Code",
          value: "code",
          // width: '5%'
        },
        {
          text: "Supplier",
          value: "supplier_code",
        },
        {
          text: "Bank From",
          value: "bank_name",
        },
        {
          text: "Bank To",
          value: "to_bank_name",
        },
        {
          text: "Value",
          sortable: false,
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      notes: "",
      itemValue: 0,
      totalPaid: 0,
      total: 0,
      totalUnpaid: 0,
      totalDiscount: 0,
      dpp: 0,
      taxValue: 0,
      purchaseInvoice: {},
      dataCharge: [],
      dataPayment: [],
      dataPaymentProposal: [],
      approveOrReject: false,
      approvalPrompt: false,
      detailPrompt: false,
      rejectPrompt: false,
      selectedData: {},
      multipleReprint: false,
      reprintPrompt: false,
      pdfUrlPopup: "",
      showPdf: false,
      pdfProxyClass: null,
      selectedPaymentRequest: null,
      proposal: [],
      usingCloseButton: false,
    };
  },
  computed: {},
  watch: {
    paymentDate() {
      this.reloadData(this.params);
    },
    bankFrom() {
      this.reloadData(this.params);
    },
    showPdf() {
      if (this.showPdf) {
        return;
      }

      if (!this.usingCloseButton) {
        this.showPdf = true;
      } else {
        this.usingCloseButton = false;
      }
    },
  },
  mounted() {
    // this.reloadData(this.params);
    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
  },
  methods: {
    handleDetailPurchaseInvoice(id) {
      this.detailPrompt = true;
      this.$vs.loading();
      this.$http.get("/api/v1/pi/payment-proposal-line/" + id).then((resp) => {
        this.$vs.loading.close();
        if (resp.code == 200) {
          this.purchaseInvoice = resp.data.purchase_invoice;
          this.dataCharge = resp.data.data_charge;
          this.dataPayment = resp.data.detail_payment;
          this.dataPaymentProposal = resp.data.payment_proposal_proses;
          this.totalPaid = resp.data.paid_value
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.itemValue = resp.data.item_value
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          const whTax = resp.data.data_line.reduce((acc, item) => {
            if (this.purchaseInvoice.IsSkb === 1) {
              return acc;
            } else {
              return acc + item.wh_tax;
            }
          }, 0);
          this.total = resp.data.total_invoice_value
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.totalUnpaid = (resp.data.unpaid_value - whTax)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.taxValue = resp.data.tax_value
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.totalDiscount = resp.data.total_discount
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: resp.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        }
      });
    },
    handleApprove(selected) {
      this.selectedData.class = "";
      this.approveOrReject = 1;
      selected.class = "highlight";
      this.selectedData = selected;
      this.selectedPaymentRequest = selected;
      this.rejectPrompt = false;
      this.approvalPrompt = true;
      console.log(this.selectedData);
    },
    handleReject(selected) {
      this.selectedData.class = "";
      this.approveOrReject = 0;
      selected.class = "highlight";
      this.selectedData = selected;
      this.approvalPrompt = false;
      this.rejectPrompt = true;
    },

    async approve() {
      try {
        // this.$vs.loading();
        // const res = await this.getProposalInfo(this.selectedPaymentRequest.ID);
        // if (res.code > 299) {
        //   this.$vs.loading.close();
        //   this.$vs.notify({
        //     color: "danger",
        //     title: "Error",
        //     text: res.message,
        //     position: "top-right",
        //     iconPack: "feather",
        //     icon: "icon-x-circle",
        //   });
        //   return;
        // }

        if (
          this.proposal.every((v) => v.purchasePaymentProposal.PrintCount === 0)
        ) {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Proposal must be printed first",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }

        this.$vs.loading.close();

        await this.doSubmit();
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
      }

      // console.log(this.selectedData)
    },
    async doSubmit() {
      try {
        this.$vs.loading();
        const params = {
          status_purchase_request: 1,
          purchase_payment_request_id: this.selectedData.ID,
          notes: this.notes,
        };
        const res = await this.$http.post(
          "/api/v1/purchase-payment-request/change-status",
          params
        );
        if (res.code == 200) {
          this.$vs.loading.close();
          this.handleClose();
          this.$vs.notify({
            color: "success",
            title: "Success",
            text: res.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          // this.approvalPrompt = false;
          // this.reloadData(this.params);
          return true;
        } else {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: res.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        }
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
      }
    },
    closeDetail() {
      this.detailPrompt = false;
      this.approvalPrompt = false;
    },
    dataTable(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/purchase-payment-request", {
            params: {
              status: 0,
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.sort,
              sort: params.order,
              po_type: "all",
              start_payment_date: this.paymentDate.startDate
                ? this.formatDate(this.paymentDate.startDate)
                : null,
              end_payment_date: this.paymentDate.endDate
                ? this.formatDate(this.paymentDate.endDate)
                : null,
              bank_id:
                !this.bankFrom || Object.keys(this.bankFrom).length === 0
                  ? null
                  : this.bankFrom.bank_id.toString(),
              bank_name:
                !this.bankFrom || Object.keys(this.bankFrom).length === 0
                  ? null
                  : this.bankFrom.bank_name,
              branch_name:
                !this.bankFrom || Object.keys(this.bankFrom).length === 0
                  ? null
                  : this.bankFrom.branch_name,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    capitalizeWords(str) {
      return str.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
      });
    },
    handleShow(selected) {
      this.selectedData.class = "";
      selected.class = "highlight";
      this.selectedData = selected;
      this.detail = true;
    },
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    handleClose() {
      this.reloadData(this.params);
      this.selectedData.class = "";
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      this.selectedPaymentRequest = null;
    },
    reloadData(params) {
      this.params = params;
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.purchasePaymentRequest;
          this.responseData = r.data;
          this.responseData.length = r.data.purchasePaymentRequest.length;
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    closeReprintPrompt() {
      this.reprintPrompt = false;
      this.proposal = [];
      this.selectedPaymentRequest = null;
      this.multipleReprint = false;
    },
    validateReprint(proposal = []) {
      return !proposal.every((v) => v.purchasePaymentProposal.PrintCount === 0);
    },
    calculateSupplierInfoHeight(doc, pageWidth, padding) {
      const supplier = this.proposal[0].supplier;
      const supplierAddr = `(${
        supplier.Code
      }) - ${supplier.Name.toUpperCase()}, ${supplier.Address.toUpperCase()}, ${supplier.SubDistrict.toUpperCase()}, ${supplier.District.toUpperCase()}, ${supplier.City.toUpperCase()}, ${supplier.Province.toUpperCase()}`;
      const supplierAddrLine = doc.splitTextToSize(
        supplierAddr,
        this.setPos(pageWidth, 7, padding)
      );

      const dimension = doc.getTextDimensions(supplierAddrLine[0]);
      return dimension.h * supplierAddrLine.length + 1;
    },

    generateMultipleDocumentInfo(doc, cursorY, pageWidth, pageHeight, padding) {
      doc.text(
        `Tgl Dibuat : ${moment().format("DD.MM.YYYY")}`,
        this.setPos(
          pageWidth,
          this.setPos(pageWidth, pageWidth - 8, padding),
          padding
        ),
        this.setPos(pageHeight, cursorY, padding)
      );

      const height = this.calculateSupplierInfoHeight(doc, pageWidth, padding);
      cursorY += height - 0.5;

      const currencyCode = this.proposal[0].purchaseInvoice.CurrencyCode;
      console.log("Currency Code", currencyCode);
      const isSameCurrency = this.proposal.every(
        (v) => v.purchaseInvoice.CurrencyCode === currencyCode
      );

      if (!isSameCurrency) {
        throw new Error("Currency code is not the same");
      }

      doc.text(
        `Currency : ${currencyCode != undefined ? currencyCode : ""}`,
        this.setPos(
          pageWidth,
          this.setPos(pageWidth, pageWidth - 8, padding),
          padding
        ),
        this.setPos(pageHeight, cursorY, padding)
      );
    },

    generateHeader(doc, startY, pageWidth, pageHeight, padding) {
      doc.setFontSize(16);
      doc.setFont("times", "bold");
      const companyName = "PT SINARMAS DISTRIBUSI NUSANTARA";
      const companyNameWidth = doc.getTextWidth(companyName);
      doc.text(
        companyName,
        this.setPos(pageWidth, (pageWidth - companyNameWidth) / 2, padding),
        this.setPos(pageHeight, startY, padding)
      );

      startY += 1;

      doc.setFontSize(12);
      doc.text(
        "Pembayaran Untuk / Kepada:",
        this.setPos(pageWidth, 0, padding),
        this.setPos(pageHeight, startY, padding)
      );

      doc.setFont("times", "normal");
      const supplier = this.proposal[0].supplier;
      const supplierAddr = `(${
        supplier.Code
      }) - ${supplier.Name.toUpperCase()}, ${supplier.Address.toUpperCase()}, ${supplier.SubDistrict.toUpperCase()}, ${supplier.District.toUpperCase()}, ${supplier.City.toUpperCase()}, ${supplier.Province.toUpperCase()}`;
      const supplierAddrLine = doc.splitTextToSize(
        supplierAddr,
        this.setPos(pageWidth, 7, padding)
      );

      startY += 0.5;

      doc.text(
        this.setPos(pageWidth, 0, padding),
        this.setPos(pageHeight, startY, padding),
        supplierAddrLine
      );

      return startY;
    },

    async printPDF(reprint = false, paymentRequest) {
      try {
        if (!reprint) {
          this.$vs.loading();

          if (!this.selectedPaymentRequest) {
            this.selectedPaymentRequest = paymentRequest;
          }

          const res = await this.getProposalInfo(
            this.selectedPaymentRequest.ID
          );

          if (res.code === 500) {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: res.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            throw new Error(res.message);
          }

          this.proposal = res.data;
          console.log("proposal", this.proposal);

          if (this.proposal.length > 1) {
            this.multipleReprint = true;
          } else {
            this.multipleReprint = false;
          }

          if (this.validateReprint(this.proposal)) {
            //validate if there is any proposal that has been printed
            this.$vs.loading.close();
            this.reprintPrompt = true;
            this.$nextTick(() => {
              const element = document.querySelector(".vs-dialog");
              element.style.width = "fit-content";
              element.style.minWitdh = "fit-content";
            });
            return;
          }
        }

        let cursorY = 0;
        const padding = 1;
        const height = 29.7;
        const width = 21;
        const pageWidth = width - padding * 2;
        const pageHeight = height - padding * 2;

        const pdf = new jsPDF({
          unit: "cm",
          format: [width, height],
        });
        // console.log("props", this.proposals);

        // const company = proposals[0].linesInfo.company;
        pdf.setFontSize(12);
        pdf.setLineWidth(0.01);
        pdf.setFillColor(219, 219, 219);

        pdf.rect(
          this.setPos(pageWidth, 0, padding),
          this.setPos(pageHeight, 0, padding),
          this.setPos(pageWidth, pageWidth, padding),
          0.5,
          "FD"
        );

        cursorY += 0.4;
        pdf.setFont("times", "bold");
        pdf.text(
          "PERMOHONAN PEMBAYARAN",
          this.setPos(pageWidth, 0.5, padding),
          this.setPos(pageHeight, cursorY, padding)
        );

        pdf.text(
          `No. OGP : ${this.selectedPaymentRequest.Code}`,
          this.setPos(pageWidth, pageWidth - 6, padding),
          this.setPos(pageHeight, cursorY, padding)
        );

        const multipleHeaderPos = cursorY;

        if (!this.multipleReprint) {
          cursorY += 1;
          cursorY = this.generateHeader(
            pdf,
            cursorY,
            pageWidth,
            pageHeight,
            padding
          );
        } else {
          cursorY += this.calculateSupplierInfoHeight(pdf, pageWidth, padding);
        }

        pdf.setFontSize(12);
        pdf.setFont("times", "normal");
        // cursorY += 1;
        // const companyName = "PT SINARMAS DISTRIBUSI NUSANTARA";
        // const companyNameWidth = pdf.getTextWidth(companyName);
        // pdf.setFontSize(16);
        // pdf.text(
        //   companyName,
        //   this.setPos(
        //     pageWidth,
        //     (pageWidth - 2.5 - companyNameWidth) / 2,
        //     padding
        //   ),
        //   this.setPos(pageHeight, cursorY, padding)
        // );

        // cursorY += 1;
        // pdf.setFontSize(12);
        // pdf.text(
        //   "Pembayaran Untuk / Kepada:",
        //   this.setPos(pageWidth, 0, padding),
        //   this.setPos(pageHeight, cursorY, padding)
        // );

        // const supplier = this.proposal[0].supplier;
        // const supplierAddr = `(${
        //   supplier.Code
        // }) - ${supplier.Name.toUpperCase()}, ${supplier.Address.toUpperCase()}, ${supplier.SubDistrict.toUpperCase()}, ${supplier.District.toUpperCase()}, ${supplier.City.toUpperCase()}, ${supplier.Province.toUpperCase()}`;
        // const supplierAddrLine = pdf.splitTextToSize(
        //   supplierAddr,
        //   this.setPos(pageWidth, 7, padding)
        // );
        // cursorY += 0.5;
        // pdf.setFont("times", "normal");
        // pdf.text(
        //   this.setPos(pageWidth, 0, padding),
        //   this.setPos(pageHeight, cursorY, padding),
        //   supplierAddrLine
        // );

        if (!this.multipleReprint) {
          const singleProposal = this.proposal[0];

          cursorY = this.generateSingleDocumentInfo(
            pdf,
            singleProposal,
            cursorY,
            pageWidth
          );

          cursorY += 0.5;

          const result = this.generateSingleDocumentInvoice(
            pdf,
            singleProposal,
            cursorY,
            pageWidth
          );

          cursorY = result.startY;

          cursorY += 0.5;
          const totalValue = result.requestPayment;
          // const currnecy = proposals[0].linesInfo.purchaseInvoice.CurrencyCode;

          const totalValueText =
            this.generateNumberSaying(totalValue) + " RUPIAH";
          const textValueHeight = pdf.getTextDimensions(totalValueText).h;
          const totalValueTextLine = pdf.splitTextToSize(
            totalValueText,
            this.setPos(pageWidth, 15, padding)
          );
          const title = "Terbilang: ";
          const titleWidth = pdf.getTextWidth(title);

          pdf.text(
            title,
            this.setPos(pageWidth, 0, padding),
            this.setPos(pageHeight, cursorY, padding)
          );
          pdf.text(
            this.setPos(pageWidth, titleWidth + 0.2, padding),
            this.setPos(pageHeight, cursorY, padding),
            totalValueTextLine
          );

          cursorY += textValueHeight * totalValueTextLine.length;

          const description =
            singleProposal.purchasePaymentProposal.CnSupplierID === 0
              ? singleProposal.purchaseInvoice.Description.replace(
                  /^\n+|\n+$/g,
                  ""
                )
              : singleProposal.creditNoteSupplier.description.replace(
                  /^\n+|\n+$/g,
                  ""
                );

          cursorY += 0.15;
          pdf.text(
            `Keterangan: ${description ? description : "-"}`,
            this.setPos(pageWidth, 0, padding),
            this.setPos(pageHeight, cursorY, padding)
          );
          cursorY += 1.8;
          this.generateFooter(pdf, cursorY, pageWidth, pageHeight);
        } else {
          // cursorY -= 0.5;

          // const singleProposal = this.proposal[0];
          // // const createdDate = this.proposals[0].PurchaseInvoiceCreatedAt;
          // pdf.text(
          //   `Tgl Dibuat : ${moment().format("DD.MM.YYYY")}`,
          //   this.setPos(
          //     pageWidth,
          //     this.setPos(pageWidth, pageWidth - 8, padding),
          //     padding
          //   ),
          //   this.setPos(pageHeight, cursorY, padding)
          // );

          // const dimension = pdf.getTextDimensions(supplierAddrLine[0]);
          // cursorY += dimension.h * supplierAddrLine.length + 1;

          // //check wether CurrencyCode for proposal is all same
          // const currencyCode = singleProposal.purchaseInvoice.CurrencyCode;
          // console.log("Currency Code", currencyCode);
          // const isSameCurrency = this.proposal.every(
          //   (v) => v.purchaseInvoice.CurrencyCode === currencyCode
          // );

          // if (!isSameCurrency) {
          //   throw new Error("Currency code is not the same");
          // }

          // pdf.text(
          //   `Currency : ${currencyCode != undefined ? currencyCode : ""}`,
          //   this.setPos(
          //     pageWidth,
          //     this.setPos(pageWidth, pageWidth - 8, padding),
          //     padding
          //   ),
          //   this.setPos(pageHeight, cursorY, padding)
          // );

          cursorY += 3;
          // const info = this.generateMultipleDocumentInvoice(
          //   pdf,
          //   this.proposal,
          //   cursorY,
          //   pageWidth
          // );
          const info = this.generateMultipleDocumentInvoiceGrouping(
            pdf,
            this.proposal,
            cursorY,
            pageWidth,
            pageHeight
          );

          cursorY = info.finalY;
          const totalValue = info.totalInvoiceValue;
          const totalValueText =
            this.generateNumberSaying(totalValue) + " RUPIAH";
          const totalValueTextLine = pdf.splitTextToSize(
            totalValueText,
            this.setPos(pageWidth, 15, padding)
          );

          cursorY -= 0.5;
          const title = "Terbilang: ";
          const titleWidth = pdf.getTextWidth(title);
          pdf.text(
            title,
            this.setPos(pageWidth, 0.4, padding),
            this.setPos(pageHeight, cursorY, padding)
          );
          pdf.text(
            this.setPos(pageWidth, titleWidth + 0.4, padding),
            this.setPos(pageHeight, cursorY, padding),
            totalValueTextLine
          );

          // const footerHeight = 9.5; //static
          // if (cursorY + footerHeight + 1.8 > pageHeight) {
          //   pdf.addPage();
          //   cursorY = this.setPos(pageHeight, 0, padding);
          // } else {
          //   const diff = pageHeight - cursorY - footerHeight;
          //   cursorY += diff;
          // }
        }

        // this.generateFooter(pdf, cursorY, pageWidth, pageHeight);

        const pageCount = pdf.internal.getNumberOfPages();
        const time = moment().format("DD.MM.YY - HH:mm:ss");
        for (let i = 1; i <= pageCount; i++) {
          if (this.multipleReprint) {
            this.generateHeader(
              pdf,
              this.setPos(pageHeight, multipleHeaderPos, padding),
              pageWidth,
              pageHeight,
              padding
            );
            this.generateMultipleDocumentInfo(
              pdf,
              this.setPos(pageHeight, multipleHeaderPos + 1, padding),
              pageWidth,
              pageHeight,
              padding
            );
            this.generateFooter(pdf, pageHeight - 5.5, pageWidth, pageHeight);
          }
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.text(
            `Page ${i} of ${pageCount}    ${time}`,
            this.setPos(pageWidth, 0, padding),
            this.setPos(height, height - 0.5, 0)
          );
        }
        // pdf.save("proposal.pdf");
        // const pdfBlob = pdf.output("blob");
        // const pdfUrl = URL.createObjectURL(pdfBlob);
        // window.open(pdfUrl, "_blank");
        this.pdfProxyClass = pdf;
        this.pdfUrlPopup = pdf.output("dataurlstring");
        this.pdfUrlPopup += "#toolbar=0";
        this.showPdf = true;

        this.$vs.loading.close();
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
      }
    },
    generateSingleDocumentInfo(doc, data, startY, pageWidth) {
      console.log("data", data);
      const header = [
        { header: "", dataKey: "title" },
        { header: "", dataKey: "value" },
      ];
      // const invoice = data.linesInfo.purchaseInvoice.purchaseInvoice;
      const body = [
        {
          title: "No Dokumen",
          value:
            data.purchasePaymentProposal.CnSupplierID === 0
              ? data.purchaseInvoice.CodeInternal
              : data.creditNoteSupplier.code,
        },
        {
          title: "Tgl Dokumen",
          value: moment(
            data.purchasePaymentProposal.CnSupplierID === 0
              ? data.purchaseInvoice.CreatedAt
              : data.creditNoteSupplier.date
          ).format("DD.MM.YYYY"),
        },
        {
          title: "No Faktur Pajak",
          value: this.getTaxnumber(data),
        },
        //{ title: "Profit Center", value: "Profit Center" },
        {
          title: "BA",
          value: this.getBA(data.purchasePaymentProposal.TerritoryID),
        },
        {
          title: "Tgl Jatuh Tempo",
          value: moment(data.purchasePaymentProposal.DueDate).format(
            "DD.MM.YYYY"
          ),
        },
        {
          title: "Jumlah Tercetak",
          value: data.purchasePaymentProposal.PrintCount + 1,
        },
      ];
      autoTable(doc, {
        startY: startY + 0.2,
        columns: header,
        body: body,
        showHead: "never",
        styles: {
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
          fontSize: 12,
          minCellHeight: 0.7,
          cellPadding: 0.2,
          font: "times",
        },
        columnStyles: {
          title: { cellWidth: 5 },
          value: { cellWidth: 5 },
        },
        theme: "plain",
        margin: { left: this.setPos(pageWidth, pageWidth - 10, 1), right: 1 },
        didParseCell(data) {
          data.cell.styles.fontSize = 11;
          const columnIndex = data.column.index;
          if (columnIndex === 0) {
            data.cell.styles.fontStyle = "bold";
          }
        },
      });

      return doc.lastAutoTable.finalY;
    },
    generateSingleDocumentInvoice(doc, data, startY, pageWidth) {
      const header = [
        { header: "Keterangan", dataKey: "title" },
        { header: "Nilai", dataKey: "value" },
      ];
      let body = [];
      let requestPayment;
      if (data.purchasePaymentProposal.CnSupplierID === 0) {
        const invoice = data.purchaseInvoice;
        const invoiceLine = [...data.linePurchaseInvoice];
        const pph = invoiceLine.reduce((acc, val) => {
          if (invoice.IsSkb !== 1) {
            return acc + val.WhTax;
          } else {
            return acc;
          }
        }, 0);
        console.log("ini pph ges", pph);
        const ppn = invoice.VatExpired > 0 ? 0 : invoice.Tax;
        let beforeTax = invoice.TotalInvoiceValue - ppn;
        requestPayment = beforeTax + ppn - pph;
        const pad = 32;
        const tax = [
          `${
            invoice.CurrencyCode != undefined ? invoice.CurrencyCode : ""
          }${this.priceFormat(beforeTax).padStart(pad, " ")}`,
          `${this.priceFormat(ppn).padStart(pad, " ")}`,
          `${this.priceFormat(pph).padStart(pad, " ")}`,
        ];
        const subTotal = `${
          invoice.CurrencyCode != undefined ? invoice.CurrencyCode : ""
        }${this.priceFormat(requestPayment).padStart(pad, " ")}`;

        let debitNoteMemo = 0;
        let partialPayment = 0;
        let creditNoteMemo = 0;

        data.purchasePayments.forEach((payment) => {
          if (payment.DebitNoteID != 0) {
            debitNoteMemo += payment.PaidInvoiceValue;
          } else {
            partialPayment += payment.PaidInvoiceValue;
          }
        });

        const payment = [
          this.priceFormat(partialPayment).padStart(pad, " "),
          this.priceFormat(creditNoteMemo).padStart(pad, " "),
          this.priceFormat(debitNoteMemo).padStart(pad, " "),
        ];

        const other = [
          this.priceFormat(0).padStart(pad, " "),
          this.priceFormat(0).padStart(pad, " "),
        ];

        requestPayment =
          requestPayment - partialPayment - creditNoteMemo - debitNoteMemo;

        const total = `${
          invoice.CurrencyCode != undefined ? invoice.CurrencyCode : ""
        }${this.priceFormat(requestPayment).padStart(pad, " ")}`;

        body = [
          {
            title: "Nilai sebelum Pajak\nPPN\n-/- PPh",
            value: tax.join("\n"),
          },
          {
            title: "Sub Total",
            value: subTotal,
          },
          {
            title: "Partial Payment\nCredit Memo\nDebit Memo",
            value: payment.join("\n"),
          },
          {
            title: "Total",
            value: total,
          },
          {
            title: "Outstanding Balance\nRounding",
            value: other.join("\n"),
          },
          {
            title: "Payment Request",
            value: this.priceFormat(requestPayment).padStart(pad, " "),
          },
        ];
      } else {
        const cnSupplier = data.creditNoteSupplier;
        // const cnSupplierLine = [...data.creditNoteSupplierLine];
        const pph = 0;
        console.log("ini pph ges", pph);
        const ppn = 0;
        let beforeTax = cnSupplier.total_credit - ppn;
        requestPayment = cnSupplier.total_credit + ppn - pph;
        const pad = 32;
        const tax = [
          `${"IDR"}${this.priceFormat(beforeTax).padStart(pad, " ")}`,
          `${this.priceFormat(ppn).padStart(pad, " ")}`,
          `${this.priceFormat(pph).padStart(pad, " ")}`,
        ];
        const subTotal = `${"IDR"}${this.priceFormat(requestPayment).padStart(
          pad,
          " "
        )}`;

        let debitNoteMemo = 0;
        let partialPayment = 0;
        let creditNoteMemo = 0;

        data.payments.forEach((payment) => {
          if (payment.DebitNoteID != 0) {
            debitNoteMemo += payment.PaidInvoiceValue;
          } else {
            partialPayment += payment.PaidInvoiceValue;
          }
        });

        const payment = [
          this.priceFormat(partialPayment).padStart(pad, " "),
          this.priceFormat(creditNoteMemo).padStart(pad, " "),
          this.priceFormat(debitNoteMemo).padStart(pad, " "),
        ];

        const other = [
          this.priceFormat(0).padStart(pad, " "),
          this.priceFormat(0).padStart(pad, " "),
        ];

        requestPayment =
          requestPayment - partialPayment - creditNoteMemo - debitNoteMemo;

        const total = `${"IDR"}${this.priceFormat(requestPayment).padStart(
          pad,
          " "
        )}`;

        body = [
          {
            title: "Nilai sebelum Pajak\nPPN\n-/- PPh",
            value: tax.join("\n"),
          },
          {
            title: "Sub Total",
            value: subTotal,
          },
          {
            title: "Partial Payment\nCredit Memo\nDebit Memo",
            value: payment.join("\n"),
          },
          {
            title: "Total",
            value: total,
          },
          {
            title: "Outstanding Balance\nRounding",
            value: other.join("\n"),
          },
          {
            title: "Payment Request",
            value: this.priceFormat(requestPayment).padStart(pad, " "),
          },
        ];
      }

      autoTable(doc, {
        startY: startY + 0.2,
        columns: header,
        body: body,
        styles: {
          lineWidth: 0.0,
          lineColor: [0, 0, 0],
          fontSize: 12,
          minCellHeight: 0.7,
          cellPadding: 0.2,
          valign: "middle",
          halign: "right",
          font: "times",
        },
        headStyles: {
          fillColor: [219, 219, 219],
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
          fontStyle: "bold",
          valign: "middle",
          halign: "center",
          cellPadding: 0.1,
          minCellHeight: 0.1,
          font: "times",
        },
        columnStyles: {
          title: { cellWidth: this.setPos(pageWidth, 12, 1) },
          value: { cellWidth: this.setPos(pageWidth, pageWidth - 14, 1) },
        },
        theme: "plain",
        margin: { left: 1, right: 1 },
        didParseCell(data) {
          data.cell.styles.fontSize = 11;
          const rowIndex = data.row.index;
          const columnIndex = data.column.index;
          const cellData = data.cell.raw;
          if (
            rowIndex % 2 !== 0 ||
            cellData.includes(header[columnIndex].header)
          ) {
            data.cell.styles.fontStyle = "bold";
          } else {
            data.cell.styles.fontStyle = "normal";
          }
        },
        didDrawCell(data) {
          const rowIndex = data.row.index;
          const columnIndex = data.column.index;
          const cellX = data.cell.x;
          const cellY = data.cell.y;
          if (rowIndex > 0 && columnIndex === 1) {
            doc.setLineWidth(0.01);
            doc.setDrawColor(0, 0, 0);
            doc.line(cellX + 2, cellY, cellX + data.cell.width, cellY);
          }
        },
      });

      return {
        startY: doc.lastAutoTable.finalY,
        requestPayment,
      };
    },
    generateMultipleDocumentInvoiceGrouping(
      doc,
      proposals,
      startY,
      pageWidth,
      pageHeight
    ) {
      const header = [
        { header: "No. Doc", dataKey: "docNumber" },
        { header: "Tgl Doc", dataKey: "docDate" },
        // { header: "PCtr", dataKey: "profitCenter" },
        { header: "BA", dataKey: "ba" },
        { header: "Tgl Jatuh Tempo", dataKey: "dueDate" },
        { header: "Nilai Sblm Pajak", dataKey: "beforeTaxValue" },
        { header: "PPN", dataKey: "ppn" },
        { header: "PPh", dataKey: "pph" },
        { header: "Payment Request", dataKey: "paymentRequest" },
      ];

      const body = [];
      let proposalIndex = 0;
      let totalInvoiceValue = 0;

      if (proposals[0].purchasePaymentProposal.CnSupplierID === 0) {
        for (const proposal of proposals) {
          const invoice = proposal.purchaseInvoice;
          const invoiceLine = [...proposal.linePurchaseInvoice];
          const pph = invoiceLine.reduce((acc, val) => {
            if (invoice.IsSkb !== 1) {
              return acc + val.WhTax;
            } else {
              return acc;
            }
          }, 0);
          const ppn = invoice.VatExpired > 0 ? 0 : invoice.Tax;
          const beforeTax = invoice.TotalInvoiceValue - ppn;
          // const paymentRequest = invoice.TotalInvoiceValue;
          let requestPayment = beforeTax + ppn - pph;

          let debitNoteMemo = 0;
          let partialPayment = 0;
          let creditNoteMemo = 0;

          proposal.purchasePayments.forEach((payment) => {
            if (payment.DebitNoteID != 0) {
              debitNoteMemo += payment.PaidInvoiceValue;
            } else {
              partialPayment += payment.PaidInvoiceValue;
            }
          });

          requestPayment =
            requestPayment - partialPayment - creditNoteMemo - debitNoteMemo;
          const tableData = {
            docNumber: proposal.PurchaseInvoiceCodeInternal,
            docDate: moment(proposal.PurchaseInvoiceCreatedAt).format(
              "DD.MM.YYYY"
            ),
            // profitCenter: "Profit Center",
            ba: this.getBA(proposal.TerritoryID),
            dueDate: moment(proposal.DueDate).format("DD.MM.YYYY"),
            beforeTaxValue: this.priceFormat(beforeTax),
            ppn: this.priceFormat(ppn),
            pph: this.priceFormat(pph),
            paymentRequest: this.priceFormat(requestPayment),
          };
          totalInvoiceValue += requestPayment;
          body.push(tableData);
          const invoiceNumber = {
            docNumber: this.getTaxForMultipleDocument(proposal),
          };
          body.push(invoiceNumber);
        }
      } else {
        for (const proposal of proposals) {
          const cnSupplier = proposal.creditNoteSupplier;
          const pph = 0;
          const ppn = 0;
          const beforeTax = cnSupplier.total_credit - ppn;
          // const paymentRequest = invoice.TotalInvoiceValue;
          let requestPayment = beforeTax + ppn - pph;

          let debitNoteMemo = 0;
          let partialPayment = 0;
          let creditNoteMemo = 0;

          proposal.purchasePayments.forEach((payment) => {
            if (payment.DebitNoteID != 0) {
              debitNoteMemo += payment.PaidInvoiceValue;
            } else {
              partialPayment += payment.PaidInvoiceValue;
            }
          });

          requestPayment =
            requestPayment - partialPayment - creditNoteMemo - debitNoteMemo;
          const tableData = {
            docNumber: cnSupplier.code,
            docDate: moment(cnSupplier.date).format("DD.MM.YYYY"),
            ba: this.getBA(proposal.TerritoryID),
            dueDate: moment(proposal.DueDate).format("DD.MM.YYYY"),
            beforeTaxValue: this.priceFormat(beforeTax),
            ppn: this.priceFormat(ppn),
            pph: this.priceFormat(pph),
            paymentRequest: this.priceFormat(requestPayment),
          };

          totalInvoiceValue += requestPayment;
          body.push(tableData);

          const invoiceNumber = {
            docNumber: this.getTaxForMultipleDocument(proposal),
          };
          body.push(invoiceNumber);
        }
      }

      const finalData = {
        docNumber: "Total",
        paymentRequest: this.priceFormat(totalInvoiceValue),
      };
      body.push(finalData);

      let startPoint = startY;
      let colWidth = 0;
      let isGreyedOut = false;
      let isShowHeader = true;
      let colHeight = 0;
      let bottomPadding = 8.5;
      const colSpan = this.setPos(pageWidth, pageWidth, 1);
      for (let i = 0; i < body.length; i++) {
        if (isShowHeader) {
          isShowHeader = false;
        }

        if (i % 2 === 0) {
          if (startPoint + 2 * colHeight > pageHeight + 2 - bottomPadding) {
            doc.addPage();
            doc.setPage(doc.internal.getNumberOfPages());
            startPoint = startY;
            isShowHeader = true;
          }
        }

        autoTable(doc, {
          startY: startPoint,
          columns: header,
          body: [body[i]],
          styles: {
            lineWidth: 0,
            lineColor: [0, 0, 0],
            fontSize: 12,
            minCellHeight: 0.1,
            cellPadding: 0.1,
            valign: "middle",
            halign: "center",
            font: "times",
          },
          showHead: i === 0 || isShowHeader ? "firstPage" : "never",
          headStyles: {
            fillColor: [219, 219, 219],
            lineWidth: 0.01,
            lineColor: [0, 0, 0],
            fontStyle: "bold",
            valign: "middle",
            cellPadding: 0.1,
            minCellHeight: 0.1,
            font: "times",
          },
          columnStyles: {
            docNumber: { cellWidth: 3.5 },
            docDate: { cellWidth: 2 },
            profitCenter: { cellWidth: 1.5 },
            ba: { cellWidth: 1.5 },
            dueDate: { cellWidth: 2 },
            beforeTaxValue: { cellWidth: 2.5 },
            ppn: { cellWidth: 2 },
            pph: { cellWidth: 2 },
          },
          theme: "plain",
          margin: { left: 1, right: 1 },
          // margin: { left: 1, right: 1, bottom: paddingBottom, top: startY },
          didParseCell(data) {
            data.cell.styles.fontSize = 9;
            const rowIndex = data.row.index;
            const columnIndex = data.column.index;
            const cellData = data.cell.raw
              ? data.cell.raw.toString()
              : "".toString();

            console.log("ini cellData", cellData);

            if (data.section === "head") {
              data.cell.styles.fontStyle = "bold";
              data.cell.styles.halign = "center";
            } else {
              if (i % 2 !== 0 && columnIndex === 0) {
                data.cell.colSpan = colSpan;
              } else if (rowIndex === body.length - 1) {
                data.cell.styles.fontStyle = "bold";
              }

              if (isGreyedOut) {
                isGreyedOut = false;
                data.cell.styles.fillColor = [188, 188, 188];
              }

              if (i % 2 === 0 && i < body.length - 1) {
                proposalIndex = Math.floor(i / 2);
                console.log("proposalIndex", proposalIndex);

                const printCount =
                  proposals[proposalIndex].purchasePaymentProposal.PrintCount;
                if (printCount > 0) {
                  isGreyedOut = true;
                  data.cell.styles.fillColor = [188, 188, 188];
                } else {
                  data.cell.styles.fillColor = [255, 255, 255];
                }
              }

              if (columnIndex > 4) {
                data.cell.styles.halign = "right";
              }
            }
          },
          didDrawCell(data) {
            const columnIndex = data.column.index;
            const cellX = data.cell.x;
            const cellY = data.cell.y;
            if (columnIndex === 0) {
              startPoint += data.cell.height;
              colHeight = data.cell.height;
            }
            if (i === body.length - 1) {
              doc.setLineWidth(0.01);
              doc.setDrawColor(0, 0, 0);
              doc.line(cellX, cellY, cellX + data.cell.width, cellY);
            }
          },
        });
      }
      return {
        finalY: doc.lastAutoTable.finalY,
        firstColWidth: colWidth,
        totalInvoiceValue,
      };
    },
    generateMultipleDocumentInvoice(doc, proposals, startY, pageWidth) {
      const header = [
        { header: "No. Doc", dataKey: "docNumber" },
        { header: "Tgl Doc", dataKey: "docDate" },
        // { header: "PCtr", dataKey: "profitCenter" },
        { header: "BA", dataKey: "ba" },
        { header: "Tgl Jatuh Tempo", dataKey: "dueDate" },
        { header: "Nilai Sblm Pajak", dataKey: "beforeTaxValue" },
        { header: "PPN", dataKey: "ppn" },
        { header: "PPh", dataKey: "pph" },
        { header: "Payment Request", dataKey: "paymentRequest" },
      ];

      const body = [];
      let proposalIndex = 0;
      let totalInvoiceValue = 0;
      let isGreyedOut = false;
      let rowIndexBefore = 0;

      if (proposals[0].purchasePaymentProposal.CnSupplierID === 0) {
        for (const proposal of proposals) {
          const invoice = proposal.purchaseInvoice;
          const invoiceLine = [...proposal.linePurchaseInvoice];
          const pph = invoiceLine.reduce((acc, val) => {
            if (invoice.IsSkb !== 1) {
              return acc + val.WhTax;
            } else {
              return acc;
            }
          }, 0);
          const ppn = invoice.VatExpired > 0 ? 0 : invoice.Tax;
          const beforeTax = invoice.TotalInvoiceValue - ppn;
          // const paymentRequest = invoice.TotalInvoiceValue;
          let requestPayment = beforeTax + ppn - pph;

          let debitNoteMemo = 0;
          let partialPayment = 0;
          let creditNoteMemo = 0;

          proposal.purchasePayments.forEach((payment) => {
            if (payment.DebitNoteID != 0) {
              debitNoteMemo += payment.PaidInvoiceValue;
            } else {
              partialPayment += payment.PaidInvoiceValue;
            }
          });

          requestPayment =
            requestPayment - partialPayment - creditNoteMemo - debitNoteMemo;
          const tableData = {
            docNumber: proposal.purchasePaymentProposal.PurchaseInvoiceCode,
            docDate: moment(
              proposal.purchasePaymentProposal.PurchaseInvoiceCreatedAt
            ).format("DD.MM.YYYY"),
            // profitCenter: "Profit Center",
            ba: this.getBA(proposal.purchasePaymentProposal.TerritoryID),
            dueDate: moment(proposal.purchasePaymentProposal.DueDate).format(
              "DD.MM.YYYY"
            ),
            beforeTaxValue: this.priceFormat(beforeTax),
            ppn: this.priceFormat(ppn),
            pph: this.priceFormat(pph),
            paymentRequest: this.priceFormat(requestPayment),
          };
          totalInvoiceValue += requestPayment;
          body.push(tableData);
          const invoiceNumber = {
            docNumber: this.getTaxForMultipleDocument(proposal),
          };
          body.push(invoiceNumber);
        }
      } else {
        for (const proposal of proposals) {
          const cnSupplier = proposal.creditNoteSupplier;
          const pph = 0;
          const ppn = 0;
          const beforeTax = cnSupplier.total_credit - ppn;
          // const paymentRequest = invoice.TotalInvoiceValue;
          let requestPayment = beforeTax + ppn - pph;

          let debitNoteMemo = 0;
          let partialPayment = 0;
          let creditNoteMemo = 0;

          proposal.purchasePayments.forEach((payment) => {
            if (payment.DebitNoteID != 0) {
              debitNoteMemo += payment.PaidInvoiceValue;
            } else {
              partialPayment += payment.PaidInvoiceValue;
            }
          });

          requestPayment =
            requestPayment - partialPayment - creditNoteMemo - debitNoteMemo;
          const tableData = {
            docNumber: cnSupplier.code,
            docDate: moment(cnSupplier.date).format("DD.MM.YYYY"),
            ba: this.getBA(proposal.purchasePaymentProposal.TerritoryID),
            dueDate: moment(proposal.purchasePaymentProposal.DueDate).format(
              "DD.MM.YYYY"
            ),
            beforeTaxValue: this.priceFormat(beforeTax),
            ppn: this.priceFormat(ppn),
            pph: this.priceFormat(pph),
            paymentRequest: this.priceFormat(requestPayment),
          };

          totalInvoiceValue += requestPayment;
          body.push(tableData);

          const invoiceNumber = {
            docNumber: this.getTaxForMultipleDocument(proposal),
          };
          body.push(invoiceNumber);
        }
      }

      const finalData = {
        docNumber: "Total",
        paymentRequest: this.priceFormat(totalInvoiceValue),
      };
      body.push(finalData);

      console.log("body gess", body);

      const colSpan = this.setPos(pageWidth, pageWidth, 1);
      let colWidth = 0;
      autoTable(doc, {
        startY: startY,
        columns: header,
        body: body,
        styles: {
          lineWidth: 0,
          lineColor: [0, 0, 0],
          fontSize: 12,
          minCellHeight: 0.1,
          cellPadding: 0.1,
          valign: "middle",
          halign: "center",
          font: "times",
        },
        headStyles: {
          fillColor: [219, 219, 219],
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
          fontStyle: "bold",
          valign: "middle",
          cellPadding: 0.1,
          minCellHeight: 0.1,
          font: "times",
        },
        columnStyles: {
          docNumber: { cellWidth: 3.5 },
          docDate: { cellWidth: 2 },
          profitCenter: { cellWidth: 1.5 },
          ba: { cellWidth: 1.5 },
          dueDate: { cellWidth: 2 },
          beforeTaxValue: { cellWidth: 2.5 },
          ppn: { cellWidth: 2 },
          pph: { cellWidth: 2 },
        },
        theme: "plain",
        margin: { left: 1, right: 1, bottom: 8.5 },
        didParseCell(data) {
          data.cell.styles.fontSize = 9;
          const rowIndex = data.row.index;
          const columnIndex = data.column.index;
          const cellData = data.cell.raw
            ? data.cell.raw.toString()
            : "".toString();

          console.log("ini cellData", cellData);

          if (
            cellData &&
            !cellData.includes(header[columnIndex].header) &&
            !cellData.includes("No Inv Vendor") &&
            rowIndex < body.length - 1
          ) {
            if (rowIndex != rowIndexBefore) {
              proposalIndex = rowIndex / 2;
              rowIndexBefore = rowIndex;
            }

            const printCount =
              proposals[proposalIndex].purchasePaymentProposal.PrintCount;
            if (printCount > 0) {
              data.cell.styles.fillColor = [188, 188, 188];
              isGreyedOut = true;
            } else {
              data.cell.styles.fillColor = [255, 255, 255];
              isGreyedOut = false;
            }
          } else if (
            cellData &&
            !cellData.includes(header[columnIndex].header) &&
            cellData.includes("No Inv Vendor") &&
            rowIndex < body.length - 1
          ) {
            if (isGreyedOut) {
              data.cell.styles.fillColor = [188, 188, 188];
              isGreyedOut = false;
            } else {
              data.cell.styles.fillColor = [255, 255, 255];
            }
          }

          if (rowIndex === 0 && columnIndex === 0) {
            colWidth = data.cell.width;
          }

          if (rowIndex % 2 !== 0) {
            data.cell.colSpan = colSpan;
          } else if (rowIndex === body.length - 1) {
            data.cell.styles.fontStyle = "bold";
          }

          if (columnIndex <= 4) {
            return;
          }

          if (!cellData || cellData.includes(header[columnIndex].header)) {
            return;
          }
          data.cell.styles.halign = "right";
        },
        didDrawCell(data) {
          const rowIndex = data.row.index;
          const cellX = data.cell.x;
          const cellY = data.cell.y;
          if (rowIndex === body.length - 1) {
            doc.setLineWidth(0.01);
            doc.setDrawColor(0, 0, 0);
            doc.line(cellX, cellY, cellX + data.cell.width, cellY);
          }
        },
      });
      return {
        finalY: doc.lastAutoTable.finalY,
        firstColWidth: colWidth,
        totalInvoiceValue,
      };
    },
    getProposalInfo(id) {
      return this.$http.get(
        "/api/v1/purchase-payment-proposal/bulk-line/" + id
      );
    },

    getTaxnumber(data) {
      if (data.purchasePaymentProposal.CnSupplierID === 0) {
        if (
          !data.purchaseInvoice.TaxReturnNumber &&
          !data.purchasePaymentProposal.VendorInvoiceNumber
        ) {
          return "-";
        } else if (!data.purchasePaymentProposal.VendorInvoiceNumber) {
          return data.purchaseInvoice.TaxReturnNumber;
        } else if (!data.purchaseInvoice.TaxReturnNumber) {
          return data.purchasePaymentProposal.VendorInvoiceNumber;
        } else {
          const taxNumber = `${data.purchaseInvoice.TaxReturnNumber} - ${data.purchasePaymentProposal.VendorInvoiceNumber}`;
          return taxNumber;
        }
      } else {
        return data.purchasePaymentProposal.VendorInvoiceNumber;
      }
    },

    getTaxForMultipleDocument(data) {
      const invoiceVendor = this.getTaxnumber(data);
      const desc =
        data.purchasePaymentProposal.CnSupplierID === 0
          ? data.purchaseInvoice.Description.replace(/^\n+|\n+$/g, "")
          : data.creditNoteSupplier.description.replace(/^\n+|\n+$/g, "");
      return `No Faktur Pajak : ${invoiceVendor}    Keteranagan : ${desc}`;
    },

    generateNumberSaying(value) {
      return angkaTerbilang(value).toUpperCase();
    },

    getBA(territoryID) {
      const territory = [...this.$store.state.operatingUnit.filter.territory];
      const ter = territory.find((v) => v.TerritoryID === territoryID);
      return ter.TerritoryCode;
    },

    handleClosePopUp() {
      this.showPdf = false;
      this.pdfUrlPopup = "";
      this.pdfProxyClass = null;
      this.proposal = [];
      this, (this.selectedPaymentRequest = null);
      this.usingCloseButton = true;
    },
    setPos(pageWidth, pos, offset) {
      if (pos >= pageWidth) {
        return pageWidth;
      } else {
        if (pos <= 0) {
          return offset;
        } else {
          return (pos += offset);
        }
      }
    },

    generateFooter(doc, startY, pageWidth, pageHeight) {
      const header = [
        { header: "Dibuat oleh:", dataKey: "a" },
        { header: "Diketahui", dataKey: "b" },
        { header: "Disetujui I:", dataKey: "c" },
        { header: "Disetujui II", dataKey: "d" },
      ];
      const width = this.setPos(pageWidth, pageWidth, 1) / 4;
      autoTable(doc, {
        startY: startY,
        columns: header,
        body: [{ a: "", b: "", c: "", d: "" }],
        styles: {
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
          fontSize: 12,
          minCellHeight: 2,
          cellPadding: 0.2,
          font: "times",
        },
        headStyles: {
          fillColor: [219, 219, 219],
          lineWidth: 0.01,
          lineColor: [0, 0, 0],
          fontStyle: "bold",
          valign: "middle",
          halign: "center",
          cellPadding: 0.1,
          minCellHeight: 0.1,
          font: "times",
        },
        columnStyles: {
          1: { cellWidth: width },
          2: { cellWidth: width },
          3: { cellWidth: width },
          4: { cellWidth: width },
        },
        theme: "plain",
        margin: { left: 1, right: 1 },
        didParseCell(data) {
          data.cell.styles.fontSize = 11;
        },
      });

      let cursorY = doc.lastAutoTable.finalY;
      let diff = doc.lastAutoTable.finalY;
      cursorY -= 0.5;

      doc.setFont("times", "bold");
      doc.text(
        "Sudah dibayar dengan :",
        this.setPos(pageWidth, 0, 1),
        this.setPos(pageHeight, cursorY, 1)
      );
      cursorY += 0.5;

      doc.setFont("times", "normal");
      doc.text(
        "Cheque/BG No.",
        this.setPos(pageWidth, 0, 1),
        this.setPos(pageHeight, cursorY, 1)
      );

      doc.text(
        ":.........................",
        this.setPos(pageWidth, this.setPos(pageWidth, 4, 1), 1),
        this.setPos(pageHeight, cursorY, 1)
      );

      cursorY += 0.7;
      doc.setFont("times", "bold");
      doc.text(
        "Diterima oleh :",
        this.setPos(pageWidth, 0, 1),
        this.setPos(pageHeight, cursorY, 1)
      );

      cursorY += 0.5;
      doc.setFont("times", "normal");
      doc.text(
        "Nama",
        this.setPos(pageWidth, 0, 1),
        this.setPos(pageHeight, cursorY, 1)
      );
      doc.text(
        ":.........................",
        this.setPos(pageWidth, this.setPos(pageWidth, 4, 1), 1),
        this.setPos(pageHeight, cursorY, 1)
      );

      cursorY += 0.5;
      doc.text(
        "Tanggal",
        this.setPos(pageWidth, 0, 1),
        this.setPos(pageHeight, cursorY, 1)
      );
      doc.text(
        ":.........................",
        this.setPos(pageWidth, this.setPos(pageWidth, 4, 1), 1),
        this.setPos(pageHeight, cursorY, 1)
      );

      // const padding = this.multipleReprint ? pageHeight + 0.7 : 1;

      cursorY += 0.7;

      if (this.multipleReprint) {
        doc.text(
          "Tanda tangan",
          this.setPos(pageWidth, 0, 1),
          pageHeight + 0.7
        );
        doc.text(
          ":.........................",
          this.setPos(pageWidth, this.setPos(pageWidth, 4, 1), 1),
          pageHeight + 0.7
        );
      } else {
        doc.text(
          "Tanda tangan",
          this.setPos(pageWidth, 0, 1),
          this.setPos(pageHeight, cursorY, 1)
        );
        doc.text(
          ":.........................",
          this.setPos(pageWidth, this.setPos(pageWidth, 4, 1), 1),
          this.setPos(pageHeight, cursorY, 1)
        );
      }
    },

    async handlePrintPDFPreview() {
      try {
        this.$vs.loading();

        // const pp_ids = this.checkedCode.map((v) => v.ID);
        // console.log("ID", pp_ids);
        // const ids = [this.proposal.purchasePaymentProposal.ID];
        const ids = this.proposal.map((v) => v.purchasePaymentProposal.ID);
        let res = await this.updatePrintCount(ids);
        if (res.code !== 200) {
          throw new Error("Failed to update print count");
        }

        res = await this.dataTable(this.params);
        if (res.code !== 200) {
          throw new Error("Failed to update print count");
        }

        console.log(this.pdfProxyClass);
        let name = "";

        if (!this.multipleReprint) {
          const code =
            this.proposal[0].purchasePaymentProposal.CnSupplierID === 0
              ? this.proposal[0].purchasePaymentProposal.Code
              : this.proposal[0].creditNoteSupplier.code;
          name = `Payment Proposals_${code}.pdf`;
        } else {
          name = "Payment Proposals.pdf";
        }
        this.pdfProxyClass.save(name);

        this.showPdf = false;
        this.pdfUrlPopup = "";
        this.pdfProxyClass = null;
        this.usingCloseButton = true;
        this.selectedPaymentRequest = null;
        this.proposal = null;
        this.$vs.loading.close();
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
        this.$vs.notify({
          color: "danger",
          title: "Print",
          text: "Failed to print",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },

    updatePrintCount(purchase_payment_ids) {
      return this.$http.put(`/api/v1/purchase-payment-request/print-count`, {
        purchase_payment_ids,
      });
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.highlight > td {
  background-color: #d0cdf0;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.vs-dialog {
  max-width: 1000px;
}
</style>
