<template>
  <vx-card title="Payment">
    <!-- <vs-button class="mb-2" @click="handleOpenForm">Create</vs-button> -->
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + 'md:w-1/4 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <!-- <div class="flex flex-col gap-1 sm:w-2/3 w-full" v-if="curretTab === 0">
          <span>Invoice Code</span>
          <multiselect
            :options="purchaseInvoiceCodeFilter"
            :multiple="true"
            :max-height="160"
            :limit="3"
            placeholder="Type Invoice Code"
            :custom-label="queryCodeFilter"
            track-by="id"
            @search-change="onAddTag"
            ref="tagInput"
            v-model="selectedCodes"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">
                  <span class="option__title">{{ props.option.code }} </span>
                </span>
              </div>
            </template>
            <template slot="tag" slot-scope="props">
              <div class="multiselect__tag">
                <span>{{ props.option.code }}</span>
                <i
                  class="multiselect__tag-icon"
                  @click.prevent
                  @mousedown.prevent.stop="props.remove(props.option, $event)"
                />
              </div>
            </template>
          </multiselect>
        </div> -->
        <div
          class="flex flex-col gap-3"
          v-if="curretTab >= 0 && curretTab <= 2"
        >
          <div class="flex flex-col gap-3 sm:w-2/3 w-full whitespace-no-wrap">
            <span>Payment Date</span>
            <div class="flex justify-between">
              <date-range-picker
                style="min-height: 40px"
                class="w-full"
                ref="picker"
                opens="center"
                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                :singleDatePicker="false"
                :timePicker="false"
                :timePicker24Hour="false"
                :showWeekNumbers="false"
                :showDropdowns="false"
                :autoApply="true"
                v-model="paymentDate"
                :linkedCalendars="true"
              >
                <template v-slot:input="picker" style="min-width: 350px">
                  {{ picker.startDate ? dateFormat(picker.startDate) : "" }} -
                  {{ picker.endDate ? dateFormat(picker.endDate) : "" }}
                </template>
              </date-range-picker>
              <vs-button
                class="ml-4"
                color="danger"
                icon-pack="feather"
                icon="icon-x"
                @click="
                  () => {
                    this.paymentDate = {
                      startDate: null,
                      endDate: null,
                    };
                  }
                "
              ></vs-button>
            </div>
          </div>
          <div class="flex flex-col gap-3 sm:w-2/3 w-full whitespace-no-wrap">
            <span>Bank From</span>
            <multiselect
              v-model="selectedBankFrom"
              :options="bankFromOptions"
              placeholder="Type to search"
              track-by="id"
              :custom-label="queryFromBank"
              :max-height="125"
              :allow-empty="true"
              :multiple="false"
            >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title">
                    <span class="option__title">
                      {{ props.option.bank_name }} -
                      {{ props.option.branch_name }}</span
                    >
                  </span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>

        <vs-tabs :color="colorx" class="mt-6" v-model="curretTab">
          <!-- <vs-tab @click="colorx = 'danger'" label="Proposal Approved">
            <div class="con-tab-ejemplo">
              <proposal :codes="selectedCodes" :showTab="showTab" />
            </div>
          </vs-tab> -->
          <vs-tab @click="colorx = 'danger'" label="Open">
            <div class="con-tab-ejemplo">
              <open :paymentDate="paymentDate" :bankFrom="selectedBankFrom" />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'success'" label="Waiting">
            <div class="con-tab-ejemplo">
              <waiting
                :paymentDate="paymentDate"
                :bankFrom="selectedBankFrom"
              />
            </div>
          </vs-tab>
          <vs-tab @click="color = 'success'" label="Payment Execute">
            <div class="con-tab-ejemplo">
              <execute
                :paymentDate="paymentDate"
                :bankFrom="selectedBankFrom"
              />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'success'" label="Payment Confirm">
            <div class="con-tab-ejemplo">
              <confirm />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'success'" label="Failed">
            <div class="con-tab-ejemplo">
              <failed />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'success'" label="Reversed">
            <div class="con-tab-ejemplo">
              <reversal />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'success'" label="Rejected">
            <div class="con-tab-ejemplo">
              <rejected />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'success'" label="Cancel">
            <div class="con-tab-ejemplo">
              <canceled />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'success'" label="Clearing With DN">
            <div class="con-tab-ejemplo">
              <clearing-with-dn />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? detailShow + 'md:w-3/4 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <!-- form component -->
            <create @close="handleClose" />
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import open from "./open/open.vue";
import create from "./form-create.vue";
import waiting from "./waiting/waiting.vue";
import execute from "./execute/execute.vue";
import confirm from "./confirm/confirm.vue";
import failed from "./failed/failed.vue";
import reversal from "./reversal/reversal.vue";
import proposal from "./proposal-approved/approved.vue";
import rejected from "./rejected/rejected.vue";
import canceled from "./canceled/canceled.vue";
import clearingWithDN from "./clearing_with_dn/index.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import debounce from "debounce";

export default {
  components: {
    open,
    waiting,
    execute,
    create,
    confirm,
    failed,
    reversal,
    proposal,
    rejected,
    canceled,
    "clearing-with-dn": clearingWithDN,
    "date-range-picker": DateRangePicker,
  },
  data() {
    return {
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      dn_id: "",
      dn_code: "",
      cn_number: "",
      cn_date: "",
      tr_number: "",
      tr_date: "",
      componentKey: 0,
      paymentDate: {
        startDate: null,
        endDate: null,
      },
      selectedBankFrom: {},
      bankFromOptions: [],
      colorx: "success",
      curretTab: 0,
      showTab: false,
      debounceFunction: null,
      purchaseInvoiceCodeFilter: [],
      selectedCodes: [],
    };
  },
  async mounted() {
    await this.fetchPurchaseInvoiceCode();
    this.getFromBank();
  },
  watch: {
    curretTab() {
      if (this.curretTab > 0) {
        this.purchaseInvoiceCodeFilter = [];
      }
    },
  },
  methods: {
    handleClose() {
      this.detail = false;
      this.componentKey += 1;
    },
    handleOpenForm() {
      this.detail = true;
      this.status_detail = "form-create";
    },
    dateFormat(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    getFromBank(search = "") {
      this.$vs.loading();
      this.$http
        .get("api/v1/purchase-payment-request/bank-filter", {
          params: {
            search,
          },
        })
        .then((resp) => {
          if (resp.code === 200) {
            this.bankFromOptions = resp.data;
            this.showTab = true;
          } else {
            this.bankFromOptions = [];
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          console.log(e);
        });
    },
    queryFromBank({ bank_name, branch_name }) {
      if (!bank_name || !branch_name) return "";
      return `${bank_name} - ${branch_name}`;
    },

    async onAddTag(tag) {
      console.log(tag);
      const tags = tag.replace(/\s+/g, "").trim().split(";");

      if (tag.includes(";")) {
        this.showTab = false;
        for (let i = 0; i < tags.length; i++) {
          const tagObject = {
            id: tags.length + 1,
            code: tags[i],
          };
          this.selectedCodes.push(tagObject);
        }

        this.$refs.tagInput.deactivate();
        this.onPurchaseInvoiceChange(tag, true);
      } else {
        this.onPurchaseInvoiceChange(tag, true);
      }
    },

    async fetchPurchaseInvoiceCode(search = "", hideTab = false) {
      try {
        console.log("hide tab", hideTab);
        const territory_ids =
          this.$store.getters["operatingUnit/getSelectedTerritoryId"];
        this.$vs.loading();
        const res = await this.$http.get(
          "/api/v1/purchase-payment-proposal/purchase-invoice-code",
          {
            params: {
              search,
              territory_ids,
              purchase_payment_proposal_type: 0,
              purchase_payment_proposal_status: 1,
            },
          }
        );
        if (res.code === 200) {
          this.purchaseInvoiceCodeFilter = res.data;
          console.log(
            "purchaseInvoiceCodeFilter",
            this.purchaseInvoiceCodeFilter
          );
        } else {
          this.purchaseInvoiceCodeFilter = [];
        }
        this.$vs.loading.close();
        if (hideTab) {
          this.showTab = true;
        }
      } catch (e) {
        this.$vs.loading.close();
      }
    },

    onPurchaseInvoiceChange(search, hideTab = false) {
      console.log("hide tab on", hideTab);

      if (this.debounceFunction) {
        this.debounceFunction.clear();
      }

      this.debounceFunction = debounce(async () => {
        await this.fetchPurchaseInvoiceCode(search, hideTab);
      }, 500);

      this.debounceFunction();
    },

    queryCodeFilter({ code, code_internal, payment_code }) {
      return `${code} ${code_internal} ${payment_code}`;
    },
  },
};
</script>
<style>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
    .core-leave-to

    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}
</style>
